// https://cf.jd.com/pages/viewpage.action?pageId=223712676
// export const enum ResultCode {
//   // 00000 成功
//   // 00001 成功,查询结果为null
//   // 10000 服务不可用，请重试
//   // 20000 缺少或非法的公共参数
//   // 30000 调用接口权限不足
//   // 40000 业务原因异常
//   Success = "00000",
//   SuccessNull = "00001",
//   ServiceUnavailable = "10000",
//   InvalidPublicParam = "20000",
//   InsufficientPermission = "30000",
//   BusinessException = "40000",
// }
export const enum TimeUint {
  Millisecond = 1,
  Second = 1000,
  Minute = 60000,
  Hour = 3600000,
}
// https://joyspace.jd.com/pages/f4xP0aRi06YbXAShIWCo
export const enum Gateway {
  Code_1 = "-1", // 网关调用异常: 一般为上送参数错误，请求参数不符合JSF接口定义，要求进行入参核对
  Code_3 = "-3", // 未知错误: 找不到JSF提供者，可能服务正在部署或下线
  Code0 = "0", // 成功: 正常返回
  Code100 = "100", // A2错误或过期: 登录态过期，重新登录
  Code101 = "101", // Token为空或者错误: 登录态过期，重新登录
  Code102 = "102", // 账户已在另一台设备登录: 登录态过期，重新登录
  Code103 = "103", // 设备信息格式错误: 企业APP版本过低，要求升级
  Code104 = "104", // A4过期或无效: 企业APP版本过低，要求升级
  Code110 = "110", // 没有登录token或者a2/a4: 未登录
  Code120 = "120", // 没有权限: 接口配置为登录后访问，没登录不能访问
  Code121 = "121", // 哎呀，系统走神了，稍后再试一下也许就好了。: JSf调用超时
  Code122 = "122", // 账号列入黑名单: 企业APP登录系统返回
  Code201 = "201", // 网关没有注册该服务: 接口未配置，或访问路径错误
  Code202 = "202", // jdpin或者企业账号为空: 接口配置为登录后访问，没登录不能访问
  Code203 = "203", // 方法已经下线: 接口已下线，不能访问
  Code204 = "204", // 没有可用JSF服务: 找不到JSF提供者，可能服务正在部署或下线
  Code205 = "205", // JSF服务提供者异常: 找不到JSF提供者，可能服务正在部署或下线
  Code206 = "206", // 安全/登录服务异常: 企业APP登录系统返回
  Code207 = "207", // 参数错误: 参数转换异常，入参结构没有按定义上送
  Code208 = "208", // 调用来源错误: 上送参数与JSf接口要求不一致
  Code301 = "301", // 当前分组尚未关联任何方法: 不会返回，分组调用已不使用
  Code302 = "302", // 组调用部分异常: 不会返回，分组调用已不使用
  Code401 = "401", // 解密异常: 企业APP版本过低，要求升级
  Code402 = "402", // 签名错误: 企业APP版本过低，要求升级
  Code403 = "403", // aks握手异常: 企业APP版本过低，要求升级
  Code404 = "404", // aks会话已过期: 企业APP版本过低，要求升级
  Code405 = "405", // 信道服务不可用: 企业APP版本过低，要求升级
  Code801 = "801", // 未登录: 超级账户未登录
  Code805 = "805", // 用户无权限: 超级账户登录校验权限未通过
  Code901 = "901", // 未登录: 场景化账户未登录
  Code902 = "902", // 未获取到商户信息: 根据pin未获取到pop商户信息,原因:该pin可能非bpin
}

export const enum BusinessApiCode {
  SUCCESS = '000000', // 成功
  SUCCESS_NO_DATE = '000001', // 查询成功，结果为空
  LEND_PARAM_ERROR = '555555', // 参数错误
  FAIL = '999999', // 系统异常
  AUTHENTICATION_FAILED = 'FAM0000501', // 身份认证失败
  RECORD_NOT_EXIST = 'FAM0000504',  // 记录不存在
  RECORD_EXIST = 'FAM0000505' // 记录重复
}

// 风控接口CODE
export const enum RiskControlApiCode {
  SUCCESS = '00000', // 成功
}

