import { summarize, SummarizeOption } from "./summary"

export const enum SgmLogType {
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  LOG,
  DEBUG = 5,
  BIZ,
  ELEMENT,
}
export const enum SgmAlertCode {
  ERROR = "ENT_CASHIER_ERROR",
  Biz_Error = "ENT_CASHIER_BIZ_ERROR",
  Biz_Info = "ENT_CASHIER_BIZ_INFO",
  Req_Info = "ENT_CASHIER_REQ_INFO",
  Res_Info = "ENT_CASHIER_RES_INFO",
  Gateway = "ENT_CASHIER_GATEWAY",
  ENCRYPT = "ENT_CASHIER_ENCRYPT",
  DataEmpty = "ENT_CASHIER_DATA_EMPTY",
  RespError = "ENT_CASHIER_RESP_ERROR",
  RespTimeTooLong = "ENT_CASHIER_RESP_TIMEOUT",
  DeRisk = "ENT_CASHIER_DE_RISK",
}

type SgmCodeWithEnv = `${SgmAlertCode}_${ENV_TYPE}`
type SgmMsg = string | number | Record<string, any>

function sendSgmCustomLog(type: SgmLogType, code: SgmCodeWithEnv, msg: SgmMsg) {
  if (!window.__sgm__) return

  let summarizeOption: Partial<SummarizeOption> = {}

  // 错误日志扩大摘要限制范围
  if (code.indexOf("ERR") > -1) {
    summarizeOption = {
      maxLength: 4096,
      maxDepth: 20,
      maxKeys: 20,
      maxArrayLength: 20,
    }
  }

  try {
    window.__sgm__.custom({
      type,
      code,
      msg: summarize(msg, summarizeOption),
    })
  } catch (e) {
    window.__sgm__.custom({
      type: SgmLogType.ERROR,
      code: SgmAlertCode.ERROR,
      msg: e,
    })
    console.log(e)
  }
}

export function sgmError(code: SgmAlertCode, msg: SgmMsg) {
  console.error(code, msg)
  sendSgmCustomLog(SgmLogType.ERROR, `${code}_${__ENV__}` as SgmCodeWithEnv, msg)
}
export function sgmWarn(code: SgmAlertCode, msg: SgmMsg) {
  console.warn(code, msg)
  sendSgmCustomLog(SgmLogType.WARN, `${code}_${__ENV__}` as SgmCodeWithEnv, msg)
}
export function sgmInfo(code: SgmAlertCode, msg: SgmMsg) {
  console.info(code, msg)
  sendSgmCustomLog(SgmLogType.INFO, `${code}_${__ENV__}` as SgmCodeWithEnv, msg)
}
export function sgmLog(code: SgmAlertCode, msg: SgmMsg) {
  console.log(code, msg)
  sendSgmCustomLog(SgmLogType.LOG, `${code}_${__ENV__}` as SgmCodeWithEnv, msg)
}
