import {onMounted, onUnmounted, ref} from "vue";
import {GlobalLocale} from "../constants/locale";

type LocaleCallback = (lang: GlobalLocale) => void

let EventListeners: Array<LocaleCallback> =  []

// ;(window as any).__lang_events__ = EventListeners
const STORAGE_KEY = "FT-locale"

export function useLocale() {
  let _innerCallback: LocaleCallback | undefined
  const locale = ref<GlobalLocale>(GlobalLocale.Default)

  const setLocaleValue = (value: GlobalLocale) => {
    if (value !== GlobalLocale.En && value !== GlobalLocale.Zh) {
      return console.warn("Unsupported language: ", locale)
    }

    locale.value = value
    localStorage.setItem(STORAGE_KEY, value)
    EventListeners.forEach(cb => cb(value))
  }

  const setLocale = (newLocale: GlobalLocale) => {
    if (newLocale !== locale.value) {
      setLocaleValue(newLocale)
    }
  }
  const getLocale = () => {
    return localStorage.getItem(STORAGE_KEY) as GlobalLocale
  }
  const onLocaleChange = (callback: (lang: GlobalLocale) => void) => {
    if (EventListeners.includes(callback)) return
    if (typeof _innerCallback === undefined) {
      console.log("onChange 不可重复调用")
    } else {
      _innerCallback = callback
      EventListeners.push(callback)
    }
  }

  onMounted(() => {
    const storeLang = getLocale()
    if (storeLang) {
      setLocaleValue(storeLang)
    } else {
      setLocaleValue(GlobalLocale.Default)
    }
  })
  onUnmounted(() => {
    EventListeners = EventListeners.filter(cb => cb !== _innerCallback)
    _innerCallback = undefined
  })

  return {
    locale,
    setLocale,
    getLocale,
    onLocaleChange,
  }
}