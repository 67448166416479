type SummarizeRecursiveFunc = (
  input: any,
  options: SummarizeOption,
  callback: SummarizeRecursiveFunc,
) => any

type SummarizeStringOption = {
  /*
  字符串最大长度
  @default 64
  */
  maxLength: number
  /*
    边缘长度
    @default 20
  */
  edgeLength: number
}
type SummarizeObjectOption = {
  /*
    最大深度
    @default 4
  */
  maxDepth: number
  /*
    对象当前层级最大元素个数
    @default 10
  */
  maxKeys: number
  /*
    数组最大长度
    @default 10
   */
  maxArrayLength: number
}
export type SummarizeOption = SummarizeObjectOption & SummarizeStringOption

/**
 * 摘要字符串，提取字符串的前N个字符和后N个字符，中间用...连接
 * @param str 原始字符串
 * @param options 配置项
 */
export function summarizeString(str: any, options: SummarizeStringOption) {
  const { maxLength = 64, edgeLength = 20 } = options
  if (typeof str !== "string") return str
  if (str.length < maxLength) return str
  if (edgeLength > maxLength / 2) throw new Error("edgeLength should be less than maxLength / 2")
  return (
    str.substring(0, edgeLength) +
    "..." +
    str.length +
    "..." +
    str.substring(str.length - edgeLength)
  )
}
/**
 * 获取数据对象摘要
 * 1. 将过长字符串字段截取
 * 2. 过长数组截取
 * 3. 过深对象截取
 * 4. 过多对象字段截取
 * @param input 任意 object
 * @param options 配置项
 * @param callback 递归回调函数
 */
export function summarizeObject(
  input: any,
  options: SummarizeOption,
  callback: SummarizeRecursiveFunc = summarizeObject,
) {
  if (!input) return input
  if (typeof input === "number") return input
  if (typeof input === "boolean") return input
  if (typeof input === "string") {
    return summarizeString(input, options)
  }

  if (Array.isArray(input)) {
    return (input as any[])
      .slice(0, options.maxArrayLength)
      .map((item) => callback(item, options, callback))
  } else if (typeof input === "object") {
    const keys = Object.keys(input)
    const result: Record<string, any> = {}
    for (let i = 0; i < keys.length; i++) {
      if (i >= options.maxKeys) break
      const key = keys[i]
      result[key] = callback(input[key], options, callback)
    }
    return result
  }
  console.warn("summarizeObject: unknown type", typeof input, input)
  return input
}

/**
 * 序列化对象提取摘要
 * @param input
 * @param options
 */
export function summarize(input: any, options: Partial<SummarizeOption> = {}) {
  let depth = 0

  const _opts = Object.assign(
    {
      maxLength: 64,
      edgeLength: 20,
      maxDepth: 10,
      maxKeys: 10,
      maxArrayLength: 10,
    },
    options,
  )

  return summarizeObject(
    input,
    _opts,
    (item: any, opts: SummarizeOption, callback: SummarizeRecursiveFunc) => {
      if (++depth >= opts.maxDepth) {
        // console.log("reached max depth.")
        return {}
      }
      // console.log("depth", opts.maxDepth, depth);
      const ret = summarizeObject(item, opts, callback)
      depth--
      // console.log("depth", opts.maxDepth, depth);
      return ret
    },
  )
}
