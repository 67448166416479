import { historyPush } from "../routes/router-main"

const noJump = /__nojump__/.test(location.href)
export function goTo(url: string) {
  if (noJump) {
    const yes = window.confirm(`页面将跳转到：${url}`)
    console.warn(`页面将跳转到：${url}`)
    if (yes) {
      location.href = url
    }
  } else {
    location.href = url
  }
}

export function open(url: string, target = "_blank") {
  const newWindow = window.open(url, target)
  if (!newWindow) {
    let form = document.createElement("form")
    form.action = url
    form.method = "GET"
    form.target = target
    document.body.appendChild(form)
    window.setTimeout(() => {
      form.submit()
      document.body.removeChild(form)
    }, 0)
  }
}

export function openTab(url: string, target = "_blank") {
  if (__DEV__) {
    const yes = window.confirm(`页面将跳转到：${url}`)
    if (yes) {
      open(url, target)
    }
  } else {
    open(url, target)
  }
}
